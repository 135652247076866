@import '../../../../scss/theme-bootstrap';

.cart-block {
  padding: 40px 0 0;
  @include breakpoint($landscape-up) {
    width: 450px;
  }
  &__content {
    padding: 0 2em;
    &--edit-cart {
      top: 33px;
      right: 50px;
      position: absolute;
      @include breakpoint($landscape-up) {
        top: 20px;
        right: 70px;
      }
    }
  }
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    padding: 0.25em;
  }
  &__header {
    @include pie-clearfix;
    border-bottom: solid 1px $color-light-gray;
    padding: 2em 0 0.5em;
    &__item-count {
      float: right;
    }
  }
  &__title {
    float: left;
    margin: 0;
    padding: 0;
    font-size: 15px;
    &__text {
      display: none;
      &--empty {
        display: inline-block;
        .cart-block--nonempty &,
        .cart-block--added & {
          display: none;
        }
      }
      &--nonempty {
        .cart-block--nonempty & {
          display: inline-block;
        }
      }
      &--added {
        .cart-block--added & {
          display: inline-block;
        }
      }
    }
  }
  &__item-count {
    color: $color-dark-gray;
    font-size: 1.25em;
    display: none;
    .cart-block--singular &--singular {
      display: block;
    }
    .cart-block--plural &--plural {
      display: block;
    }
    .cart-block--empty & {
      display: none;
    }
    .cart-block--added & {
      display: none;
    }
  }
  &__items {
    // In the "new" state, only show the items that were just added:
    &--new {
      display: none;
      .cart-block--added & {
        display: block;
      }
    }
    .cart-block--added &--all {
      display: none;
    }
    &__view-bag {
      display: none;
      padding: 1em 0 2em;
    }
    .cart-item__subheading {
      font-size: inherit;
    }
  }
  &__footer {
    padding-top: 1.25em;
    padding-bottom: 2em;
    &__summary {
      @include pie-clearfix;
      display: none;
      margin: 0 0 1em;
      .cart-block--nonempty &,
      .cart-block--added & {
        display: block;
      }
    }
    &__button--delivery-container {
      margin-top: 10px;
      text-align: center;
      .link {
        font-size: 12px;
        border-bottom: none;
      }
    }
    &__item-count {
      float: left;
      // Intentionally supressing item count in footer, see ORNF-122
      display: none;
      .cart-block--plural & {
        display: none;
      }
    }
    &__subtotal {
      color: $color-black;
      display: block;
      font-weight: bold;
      &__label {
        font-size: 1.25em;
        float: left;
      }
      &__value {
        font-size: 1.25em;
        float: right;
      }
      .cart-block--added & {
        display: none;
      }
    }
    &__button {
      width: 100%;
      &--checkout {
        display: none;
        .cart-block--nonempty &,
        .cart-block--added & {
          display: block;
        }
      }
      &--continue-shopping {
        .cart-block--nonempty &,
        .cart-block--added & {
          display: none;
        }
      }
    }
    &__legal {
      float: #{$rdirection};
    }
  }
}

.site-header__utility {
  .cart-block--nonempty,
  .cart-block--added {
    .cart-block {
      &__title {
        &__text {
          &--empty {
            display: none;
          }
        }
      }
      &__footer {
        &__button {
          &--checkout {
            display: inline-block;
            color: $color-white;
          }
        }
      }
    }
  }
  .cart-block {
    &__content {
      &--edit-cart {
        top: 33px;
        right: 50px;
        position: absolute;
        @include breakpoint($large-up) {
          top: 20px;
          right: 70px;
        }
      }
    }
    &__footer {
      &__button {
        &--checkout {
          display: none;
        }
      }
    }
    &__title {
      &__text {
        &--empty {
          display: inline-block;
        }
      }
    }
  }
}
